import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useWindowSize } from 'hooks'
import { useRouter } from 'next/router'
import { Link } from '@mui/material'
import { jsx } from '@emotion/react'

const SimpleAccordion = ({
  index,
  name,
  accordianLength,
  open,
  title,
  content,
  btnText,
  url,
  handleChange,
  textColor,
  backgroundColor,
  deal,
  dealHeight,
}: {
  index: number
  name: string
  accordianLength: number
  open: boolean
  title: string
  content: any
  btnText?: string
  url?: string
  handleChange: Function
  textColor?: string
  backgroundColor?: string
  deal?: boolean
  dealHeight?: string
}) => {
  const windowSize = useWindowSize()
  const pageWidth = windowSize?.width || 0
  const router = useRouter()

  const isDeal = deal

  {
    return (
      <div>
        <Accordion
          expanded={open}
          onChange={() => handleChange(name, index)}
          sx={{
            '&.MuiAccordion-rounded': {
              borderTopRightRadius:
                index === 0 ? '12px !important' : '0px !important',
              borderTopLeftRadius:
                index === 0 ? '12px !important' : '0px !important',
              borderBottomRightRadius:
                index === accordianLength - 1
                  ? '12px !important'
                  : '0px !important',
              borderBottomLeftRadius:
                index === accordianLength - 1
                  ? '12px !important'
                  : '0px !important',
            },
          }}
          classes={{
            root:
              '!shadow-none !overflow-hidden !border-solid !border !border-sky-500' +
              (index === 0
                ? '!rounded-t-[12px] !border-b-0 '
                : index === accordianLength - 1
                ? '!rounded-b-[12px] !rounded-t-none '
                : '!rounded-none !border-b-0 '),
          }}
          className="!border-sky-500 !bg-sky-100 "
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{
                  color: open && deal ? '#F6F9F8' : '',
                }}
              />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              '&.Mui-expanded': {
                backgroundColor: backgroundColor
                  ? `${backgroundColor} !important`
                  : '',
              },
            }}
            classes={{
              root:
                '!shadow-none ' +
                (open ? '!bg-sky-500 ' : '!bg-sky-100 ') +
                (pageWidth >= 1024 ? '!h-[84px] ' : 'h-[48px] '),
            }}
          >
            <div
              className={`uppercase  ${
                open && textColor
                  ? `text-${textColor}`
                  : open
                  ? 'text-deep-500'
                  : 'text-night-800'
              }  font-knockout-48 font-[360] lg:text-[40px] lg:leading-[36px] text-[26px] leading-[24px]`}
            >
              {title}
            </div>
          </AccordionSummary>
          <AccordionDetails className="bg-sky-50">
            <div
              style={{
                height:
                  isDeal && dealHeight && pageWidth >= 1024
                    ? `${dealHeight}px`
                    : '',
              }}
              className={`font-ringside-ssm-medium text-grayscale-coal-900 lg:p-[16px_24px] p-[12px] font-[400] lg:text-[16px] lg:leading-[24px] text-[13px] leading-[20px] flex flex-col`}
            >
              {content}
              <div className="w-full text-end">
                {url ? (
                  <Link href={url}>
                    <a href={url}>
                      <button
                        onClick={url ? () => router.push(url) : null}
                        className={`h-[64px] border-transparent w-fit text-end p-0 ${
                          url ? '' : null
                        }`}
                      >
                        <span className="btn-underline-tw hover:cursor-pointer">
                          {btnText}
                        </span>
                      </button>
                    </a>
                  </Link>
                ) : null}
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    )
  }
}

export default SimpleAccordion
