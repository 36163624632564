import { getProductModifiers, getSubscriptionPrice } from '@utils/products'
import { useState, useEffect } from 'react'
import api from 'services/api'
import { Product, ProductVariantOptions } from 'types/products'
import Pricing from './Pricing'
import Reviews from './Reviews'
import { slugifyName } from '@utils/products'
import { useRouter } from 'next/router'
import AddToCartButton from '@components/Button/AddToCartButton'
import JoinWaitListButton from '../ProductCard/JoinWaitListButton'
import Link from 'next/link'

interface ProductInfoProps {
  product: Product
  deal?: boolean
  selectedVariant?: ProductVariantOptions | null
}

const ProductInfo = ({ product, deal, selectedVariant }: ProductInfoProps) => {
  const router = useRouter()

  const handleClick = () => {
    router.push(`/product/${slugifyName(product.name)}`)
  }

  const [stars, setStars] = useState()
  const [reviews, setReviews] = useState()
  const subscriptions = getProductModifiers(product, 'Sub Options')
  const hasSubscription = !!subscriptions
  const singlePrice = product?.price
  const [subPrice, setSubPrice] = useState(
    hasSubscription ? getSubscriptionPrice(product) : product?.price
  )

  useEffect(() => {
    const fetchData = async () => {
      return await api.getReviewsBottomLine(product.id.toString())
    }
    fetchData().then((result) => {
      const { stars, reviews } = result?.data || {}
      setStars(stars)
      setReviews(reviews)
    })
  }, [])

  return (
    <>
      <div className="product-info-container-tw hover:cursor-pointer">
        <Link href={`/product/${slugifyName(product.name)}`}>
          <a
            className="max-w-full"
            href={`/product/${slugifyName(product.name)}`}
          >
            <span className="product-info-title-tw">{product.name}</span>
            <div onClick={handleClick} className="product-info-reviews-tw">
              {product.id != 232 && (
                <Reviews stars={stars} reviews={reviews} product={product} />
              )}
            </div>
            <div onClick={handleClick} className="product-info-pricing-tw">
              <Pricing
                singlePrice={singlePrice}
                subPrice={subPrice}
                onePrice={false}
                productId={product?.id || 0}
              />
            </div>
          </a>
        </Link>
        {!deal && product.id ? (
          <div className="flex flex-col w-full pt-3 gap-2">
            {/* <BuyNow product={product} setHasBuyNow={setHasBuyNow} /> */}
            <AddToCartButton
              product={product}
              selectedVariant={selectedVariant}
            />
          </div>
        ) : !deal ? (
          <div className="w-full pt-3">
            <JoinWaitListButton />
          </div>
        ) : null}
      </div>
    </>
  )
}

export default ProductInfo
