import { Product } from 'types/products'

const Reviews = ({
  stars,
  reviews,
  product,
}: {
  stars: number
  reviews: string
  product: Product
}) => {
  let starConfig = {
    1: '/img/reviews/review_star_full.svg',
    2: '/img/reviews/review_star_full.svg',
    3: '/img/reviews/review_star_full.svg',
    4: '/img/reviews/review_star_full.svg',
    5: '/img/reviews/review_star_full.svg',
  }
  for (let i = 1; i < 6; i++) {
    if (stars > i || stars > i - 0.3) {
      starConfig[i] = '/img/reviews/review_star_full.svg'
    } else if (stars < i - 0.8) {
      starConfig[i] = '/img/reviews/review_star_empty.svg'
    } else {
      starConfig[i] = '/img/reviews/review_star_half.svg'
    }
  }

  return (
    <>
      {reviews ? (
        <>
          {Object.entries(starConfig).map(([key, value], index) => {
            return (
              <img
                className="product-info-reviews-stars-tw"
                alt="review stars"
                src={value}
                key={index}
              />
            )
          })}
          <span className="product-info-reviews-text-tw">
            {`${reviews} Reviews`}
          </span>
        </>
      ) : (
        <span className="product-info-reviews-text-tw">
          Be The First To Review!
        </span>
      )}
    </>
  )
}

export default Reviews
