import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import Link from 'next/link'
import ArticleCard from './ArticleCard'
import { format, parseISO } from 'date-fns'

export default function BlogSnippet({
  posts,
  headline,
  bgColor,
  homePageBgColor,
}: {
  posts: any
  headline: string
  bgColor: string
  homePageBgColor?: string
}) {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }

  return (
    <>
      <div className={`flex justify-center bg-cream-300 ${homePageBgColor}`}>
        <div className="flex flex-col items-center justify-center  lg:gap-[32px] gap-[16px] max-w-[100%] md:max-w-[1100px]">
          <div className="text-center max-w-[300px] md:max-w-none flex justify-center m-auto">
            <div className="text-night-800 font-knockout-71 font-[400] leading-[30px] text-[40px] lg:text-[60px] mt-0 mb-4 md:mb-8 uppercase">
              {headline}
            </div>
          </div>
          <div className="block w-[320px] lg:w-full lg:hidden">
            {/* @ts-ignore */}
            <Carousel
              responsive={responsive}
              infinite={true}
              autoPlay={false}
              ssr={true} // means to render carousel on server-side.
              draggable={false}
              shouldResetAutoplay={false}
            >
              {posts?.map((post) => (
                <div className="flex justify-center ">
                  {/* @ts-ignore */}
                  <ArticleCard
                    img={`https://store.goodranchers.com${post.thumbnail_path}`}
                    title={post.title}
                    url={post.url}
                    bgColor={bgColor}
                    tags={post.tags}
                    date={format(parseISO(post.published_date_iso8601), 'PPP')}
                  />
                </div>
              ))}
            </Carousel>
          </div>
          <div className="hidden lg:flex flex-col items-center justify-center w-full">
            <div className="flex flex-row w-full justify-center items-start">
              {posts?.map((post) => (
                <div className="flex justify-center max-w-full">
                  {/* @ts-ignore */}
                  <ArticleCard
                    img={`https://store.goodranchers.com${post.thumbnail_path}`}
                    title={post.title}
                    url={post.url}
                    bgColor={bgColor}
                    tags={post.tags}
                    date={format(parseISO(post.published_date_iso8601), 'PPP')}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="flex justify-center text-center mt-8 btn-underline-tw">
            <Link href={'/blog'}>
              <a href="/blog" className="btn-underline-tw pb-[60px]">
                View all articles
              </a>
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}
