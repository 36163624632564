import SimpleAccordion from './Accordion'
import { useState } from 'react'

export default function FAQ() {
  const items = [
    {
      title: 'Our Commitment',
      content: `We’re proud to offer one of the most value-filled meat delivery subscriptions on the market. You get $25 off every box of your subscription. These savings along with the power to pause your subscription for up to 90 days and a Satisfaction Guarantee on each box are the reasons we won Best Food Subscription of 2022 and 2023.`,
      btnText: 'Find Your Subscription ',
      url: '/shop',
    },
    {
      title: 'Our Process',
      content: `The proof is in the protein. More and more companies are sourcing imported meat to save money at the detriment of your food quality and safety. We\’re proud to source 100% of our meat from American farmers and ranchers. That\’s a guarantee. With us, you\’ll always get America\’s best meat sourced from real farms here on US soil.`,
      btnText: 'Our Full Process',
      url: '/about',
    },
    {
      title: 'Our Story',
      content: `Good Ranchers started in 2018 from the Spell family\’s search for food they could trust. What they found is that to eat better, you have to know better. That\’s why Good Ranchers wants to fill your fridge with the best food, and equip you with the information you need to know to make good food decisions. Our story is still about one family, but that family is now thousands of people like yourself who want the best and won\’t settle for the “less” they\’re being sold. Join us at the table and share in the good.`,
      btnText: 'The Full Story',
      url: '/about',
    },
    {
      title: 'Help Center',
      content: `We love questions! Why? Because we have the answers, and we\’re here to share them. That\’s why our phone lines are open, our inbox is ready, and our Help Center is stocked with the most frequently asked questions we get. Never hesitate to reach out for help. We\’re here for you.`,
      btnText: 'Visit Help Center',
      url: '/help-center',
    },
    {
      title: 'Joke of the Week',
      content: `Wanna hear a sucky joke? A vampire cow is called Cow-nt Dracula, and the only way to kill it is a steak to the heart.`,
      btnText: null,
      url: null,
    },
  ]

  const [itemOpen, setItemOpen] = useState(
    items.map((item, index) => ({
      id: index,
      open: false,
    }))
  )

  const handleChange = (name, index) => {
    setItemOpen(
      itemOpen.map((item) =>
        item.id === index ? { ...item, open: !item.open } : { ...item }
      )
    )
  }

  return (
    <>
      <div className="flex justify-center m-auto p-5 max-w-[100%] md:max-w-[800px]">
        <div className="flex flex-col lg:gap-[32px] gap-[16px] text-cream-400">
          <div className="flex flex-col text-center">
            <div className="text-night-800 font-knockout-71 leading-[30px] lg:leading-[45px] font-[400] text-[40px] lg:text-[60px] mb-4 md:mb-8 uppercase pt-6">
              Get to know us
            </div>
          </div>
          <div className="flex flex-col">
            {items.map((item, index) => {
              return (
                <SimpleAccordion
                  key={index}
                  name={item.title}
                  accordianLength={items.length}
                  open={itemOpen[index].open}
                  index={index}
                  title={item.title}
                  content={item.content}
                  btnText={item.btnText}
                  url={item.url}
                  handleChange={handleChange}
                />
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}
