import _ from 'lodash'
import { Product } from 'types/products'
import { slugifyName } from '@utils/products'
import { useRouter } from 'next/router'
import { contents } from '../../../utils/ProductData'

const ProteinsColors = {
  beef: {
    border: 'border-beef-500',
    color: 'bg-beef-50',
    text: 'text-beef-500',
  },
  chicken: {
    border: 'border-yolk-500',
    color: 'bg-yolk-200',
    text: 'text-yolk-800',
  },
  seafood: {
    border: 'border-wave-500',
    color: 'bg-wave-200',
    text: 'text-seafood-500',
  },
  pork: {
    border: 'border-pork-500',
    color: 'bg-pork-200',
    text: 'text-pork-700',
  },
}

const ProteinIndicator = ({ product }: { product: Product }) => {
  const proteins = contents[String(product?.id)]?.tags || ['beef']
  const router = useRouter()

  const handleClick = () => {
    router.push(`/product/${slugifyName(product.name)}`)
  }

  const width = getWidth(proteins)
  return (
    <>
      <div
        onClick={handleClick}
        className="w-full lg:h-[32px] sm:h-[17px] h-[12px] flex flex-row flex-none flex-grow-0 z-[1] items-start p-0 hover:cursor-pointer"
      >
        {proteins.map((protein, index) => {
          return (
            <div
              className={`protein-indicator-item-tw ${width} ${ProteinsColors[protein]?.color} ${ProteinsColors[protein]?.border}`}
              key={index}
            >
              <span
                className={`protein-text-tw ${ProteinsColors[protein]?.text}`}
              >
                {protein}
              </span>
            </div>
          )
        })}
      </div>
    </>
  )
}

enum Widths {
  HALF_WIDTH = 'w-1/2',
  FULL_WIDTH = 'w-full',
  THIRD_WIDTH = 'w-1/3',
}

const getWidth = (proteins) => {
  if (proteins.length === 1) {
    return Widths.FULL_WIDTH
  } else if (proteins.length === 2) {
    return Widths.HALF_WIDTH
  } else if (proteins.length === 3) {
    return Widths.THIRD_WIDTH
  } else {
    return Widths.FULL_WIDTH
  }
}

export default ProteinIndicator
