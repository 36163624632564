const Pricing = ({
  singlePrice,
  subPrice,
  onePrice,
  productId,
}: {
  singlePrice: string | number
  subPrice: number
  onePrice: boolean
  productId: number
}) => {
  return (
    <>
      <div className="product-info-pricing-sub-tw">${subPrice}</div>
      {productId !== 136 && productId !== 232 ? (
        <div className="product-info-pricing-single-tw">${singlePrice}</div>
      ) : null}
    </>
  )
}

export default Pricing
