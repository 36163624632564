import api from '@services/api'
import { openDrawer } from '@services/index'
import { getProductModifiers } from '@utils/products'
import { GlobalContext } from 'contexts'
import { UserContext } from 'contexts/user-context'
import { useContext, useEffect } from 'react'
import { UserActionType } from 'types/user-context'
import { getSubscriptionPrice, transformVariant } from '@utils/products'
import { useState } from 'react'
import { sha256 } from 'js-sha256'
import { useFacebook, useTaboola, useKlaviyo } from 'services/tracking'
import { useWindowSize } from 'hooks'
import * as _ from 'lodash'
import { Product, ProductVariantOptions } from 'types/products'
import { CartPayload } from 'types/carts'

declare const window: any

interface AddToCartButtonProps {
  product: Product
  isMerch?: boolean
  selectedVariant?: ProductVariantOptions | null
}

const AddToCartButton = ({
  product,
  isMerch,
  selectedVariant,
}: AddToCartButtonProps) => {
  const { dispatch: globalDispatch } = useContext(GlobalContext)
  const {
    dispatch: userDispatch,
    state: { cart },
  } = useContext(UserContext)

  const handleClick = () => {
    addToCart()
  }

  const [subPrice, setSubPrice] = useState(null)
  const [singlePrice, setSinglePrice] = useState(null)
  const [quantity, setQuantity] = useState(1)
  const subscriptions = getProductModifiers(product, 'Sub Options')
  const hasSubscription = !!subscriptions
  const subscriptionOptions = hasSubscription
    ? subscriptions.option_values
    : null

  const [selectedOption, setSelectedOption] = useState(
    hasSubscription
      ? subscriptionOptions.find(
          (option) => option.label.toLowerCase() === 'every 4 weeks'
        )
      : null
  )

  const facebookPixel = useFacebook()
  const taboolaPixel = useTaboola()
  const klaviyoPixel = useKlaviyo()

  const windowSize = useWindowSize()
  const pageWidth = windowSize?.width || 0

  var deviceType = pageWidth > 768 ? 'desktop' : 'mobile'

  const {
    state: { user },
  } = useContext(UserContext)

  useEffect(() => {
    const sub = hasSubscription ? getSubscriptionPrice(product) : product?.price
    setSubPrice(sub)
    const singlePrice = product?.price
    setSinglePrice(singlePrice)

    const selectedOptionValue = hasSubscription
      ? subscriptionOptions.find(
          (option) => option.label.toLowerCase() === 'every 4 weeks'
        )
      : null
    selectedOption ? setSelectedOption(selectedOptionValue) : null
  }, [product])

  const addToCart = async () => {
    const data: CartPayload = {
      product_id: String(product?.id),
      quantity: 1,
      option_selections: [],
    }

    const nonSubscriptionProducts = [125, 136, 232, 241, 242, 243, 254]

    if (!!!isMerch) {
      const subscriptions = getProductModifiers(product, 'Sub Options')
      const fourWeekSub = subscriptions?.option_values.find(
        (option) => option.label.toLowerCase() === 'every 4 weeks'
      )

      if (!nonSubscriptionProducts.includes(product.id)) {
        data.option_selections.push({
          option_id: fourWeekSub.option_id,
          option_value: fourWeekSub.id,
        })
      }

      if (
        !_.isNull(selectedVariant) &&
        !_.isUndefined(selectedVariant) &&
        !_.isEmpty(selectedVariant)
      ) {
        data.option_selections.push(transformVariant(selectedVariant))
      }
    }

    try {
      const cartRes = await api.addItemToCart(data)
      const { data: cartData } = cartRes.data
      userDispatch({ type: UserActionType.SET_CART, payload: cartData })

      globalDispatch(openDrawer())

      let emailEncrypted = sha256(user ? user.email : '')
      {
        process.env.NODE_ENV === 'production' &&
          window.criteo_q.push(
            {
              event: 'setAccount',
              account: process.env.NEXT_PUBLIC_CRITEO_ACCOUNT,
            },
            {
              event: 'setEmail',
              email: `${user && user.email ? emailEncrypted : ''}`,
              hash_method: 'sha256',
            },
            { event: 'setSiteType', type: deviceType },
            {
              event: 'addToCart',
              item: [
                {
                  id: product.id,
                  price:
                    selectedOption?.label?.toLowerCase() === 'ONE-TIME PURCHASE'
                      ? singlePrice
                      : subPrice,
                  quantity: quantity,
                },
              ],
            }
          )

        window.twq('event', 'tw-of5ov-of8gj', {
          value:
            selectedOption?.label?.toLowerCase() === 'ONE-TIME PURCHASE'
              ? singlePrice
              : subPrice, // use this to pass the value of the conversion (e.g. 5.00)
          currency: 'USD',
          contents: [
            {
              content_type: 'Boxes',
              content_id: product.id,
              content_name: product.name,
              content_price:
                selectedOption?.label?.toLowerCase() === 'ONE-TIME PURCHASE'
                  ? singlePrice
                  : subPrice,
              num_items: quantity,
            },
          ],
        })

        window.ttq.track('AddToCart', {
          content_id: product.id,
          content_type: 'product',
          content_name: product.name,
          quantity: quantity,
          price:
            selectedOption?.label?.toLowerCase() === 'ONE-TIME PURCHASE'
              ? singlePrice
              : subPrice,
          currency: 'USD',
        })

        window.pintrk('track', 'addtocart', {
          value:
            selectedOption?.label?.toLowerCase() === 'ONE-TIME PURCHASE'
              ? singlePrice
              : subPrice,
          order_quantity: quantity,
          currency: 'USD',
          line_items: [
            {
              product_name: product.name,
            },
          ],
        })

        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({ ecommerce: null })
        window.dataLayer.push({
          event: 'add_to_cart',
          ecommerce: {
            items: [
              {
                item_name: product.name,
                item_id: product.id,
                price:
                  selectedOption?.label?.toLowerCase() === 'ONE-TIME PURCHASE'
                    ? singlePrice
                    : subPrice,
                item_brand: 'Good Ranchers',
                item_category: 'Boxes',
                item_variant: selectedOption.label,
                quantity: quantity,
              },
            ],
          },
        })

        // tracking logic
        facebookPixel.track('AddToCart', {
          content_ids: [product.sku],
          content_name: product.name,
          contents_type: 'product',
          contents: [
            {
              id: product.sku,
              quantity: 1,
              content_name: product.name,
              unit_price:
                selectedOption?.label?.toLowerCase() === 'ONE-TIME PURCHASE'
                  ? singlePrice
                  : subPrice,
              currency: 'usd',
              subscription: selectedOption
                ? selectedOption?.label
                : 'One-time purchase',
            },
          ],
          currency: 'usd',
          value:
            selectedOption?.label?.toLowerCase() === 'ONE-TIME PURCHASE'
              ? singlePrice
              : subPrice,
        })
        taboolaPixel.track('AddToCart')
        const klaviyoItem = {
          ProductID: product.id,
          SKU: product.sku,
          ProductName: product.name,
          Quantity: quantity,
          ItemPrice:
            selectedOption?.label?.toLowerCase() === 'ONE-TIME PURCHASE'
              ? singlePrice
              : subPrice,
          RowTotal:
            selectedOption?.label?.toLowerCase() === 'ONE-TIME PURCHASE'
              ? singlePrice * quantity
              : subPrice * quantity,
          ProductURL: product.custom_url.url.replace(
            'store.goodranchers.com',
            'www.goodranchers.com'
          ),
          MetaData: {
            subscription: selectedOption
              ? selectedOption?.label
              : 'One-time purchase',
          },
        }
        klaviyoPixel.track('Added to Cart', {
          $value: data.price,
          AddedItemProductName: product.name,
          AddedItemProductID: product.id,
          AddedItemSKU: product.sku,
          AddedItemURL: `https://www.goodranchers.com${product.custom_url.url}`,
          AddedItemPrice:
            selectedOption?.label?.toLowerCase() === 'ONE-TIME PURCHASE'
              ? singlePrice
              : subPrice,
          AddedItemQuantity: quantity,
          MetaData: {
            subscription: selectedOption
              ? selectedOption?.label
              : 'One-time purchase',
          },
          CheckoutURL: 'https://www.goodranchers.com/checkout',
          Items: (cart ? cart.line_items.physical_items : [])
            .map((item) => ({
              ProductID: item.product_id,
              SKU: item.sku,
              ProductName: item.name,
              Quantity: item.quantity,
              ItemPrice: item.list_price,
              RowTotal: item.list_price * item.quantity,
              ProductURL: item.url.replace(
                'store.goodranchers',
                'www.goodranchers'
              ),
              MetaData: {
                subscription: selectedOption?.label.toLowerCase(),
              },
            }))
            .concat([klaviyoItem]),
        })
      }
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <>
      <div onClick={handleClick}>
        <button
          className={`btn-secondary-tw bg-yolk-500 hover:cursor-pointer flex flex-col items-center content-center justify-center text-center w-full `}
        >
          <span className="font-[900] lg:text-[20px] sm:text-[16px] text-[16px] lg:leading-[20px] sm:leading-[16px] leading-[16px] text-center uppercase text-grayscale-coal-900">
            Add to Cart
          </span>
        </button>
      </div>
    </>
  )
}

export default AddToCartButton
