import { Product } from 'types/products'
import ProductCard from '@components/Card/HomePageProductCard/ProductCard'
import { getActiveBoxList, sortProducts } from '@utils/products'
import { Link } from '@mui/material'
import { useState, useEffect } from 'react'

export async function getStaticProps() {
  const filteredProducts = await getActiveBoxList()

  return {
    props: {
      products: filteredProducts,
    },
  }
}

export default function ProductListHome({ products }: { products: Product[] }) {
  const [sortedProducts, setSortedProducts] = useState([])

  useEffect(() => {
    setSortedProducts(sortProducts(products))
  }, [])

  return (
    <>
      <div className="bg-white  pt-[40px] lg:pt-[80px]">
        <div className="justify-center m-auto flex w-full">
          <div className="flex flex-col gap-[16px]">
            <div className="text-beef-500 font-knockout-71 text-[40px] leading-[30px] mb-8 sm:text-[60px] mt-0 text-center uppercase lg:mb-[50px]">
              Choose your boxes
            </div>
            <div className="grid md:hidden overflow-hidden grid-cols-2 grid-rows-2  lg:grid-cols-3 gap-4  bg-transparent">
              {sortedProducts?.slice(0, 4)?.map((product, index) => (
                <div
                  className="flex justify-center w-fit m-auto border-gray-700 border rounded-lg border-solid"
                  key={index}
                >
                  <ProductCard key={product.name} product={product} />
                </div>
              ))}
            </div>
            <div className="hidden md:grid overflow-hidden grid-cols-2 grid-rows-2 lg:grid-cols-3 gap-8  bg-transparent lg:conent-start">
              {sortProducts(products)
                ?.slice(0, 6)
                ?.map((product, index) => (
                  <div
                    className="flex justify-center w-fit border-gray-700 border rounded-lg border-solid"
                    key={index}
                  >
                    <ProductCard key={product.name} product={product} />
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div>
          <div
            className="justify-center m-auto flex w-full mt-[-280px] md:mt-[-380px] absolute h-[80px] bg-white"
            style={{
              WebkitMaskImage:
                '-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,0)), to(rgba(0,0,0,5)))',
            }}
          ></div>
          <div className="justify-center m-auto w-full mt-[-200px] absolute bg-white h-[200px] md:h-[300px] md:mt-[-300px] bg-gradient-to-b from-[rgba((0,0,0,.75))] to-white">
            <div className="w-full m-auto">
              <div className="text-center">
                <Link href="/shop">
                  <button className="font-ringside-compressed-ssm-black text-sm lg:text-lg text-darkBlue bg-yolk-500 uppercase py-3 px-4 border-3 border-yolk-500  border-solid	hover:bg-yolk-300 hover:border-yolk-300 hover:cursor-pointer active:border-yolk-300 active:bg-yolk-300 no-underline">
                    View all Our Boxes
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
