export default function AsFeaturedIn() {
  const logos = [
    {
      logoMobile: '/img/rebrand/ForbesLogo.svg',
      altText: 'Forbes',
      style: 'lg:h-[50px] h-[32px]',
    },
    {
      logoMobile: '/img/rebrand/GQLogo.svg',
      altText: 'GQ',
      style: 'lg:h-[64px] h-[42px]',
    },
    {
      logoMobile: '/img/rebrand/IncLogo.svg',
      altText: 'Inc',
      style: 'lg:h-[56px] h-[36px]',
    },
    {
      logoMobile: '/img/rebrand/MensJournalLogo.svg',
      altText: 'Mens Journal',
      style: 'lg:h-[50px] h-[32px]',
    },
  ]

  return (
    <>
      <div className="flex justify-center m-auto">
        <div className="text-center text-cream-400 uppercase pb-[48px] md:pb-[220px]">
          <p className="tracking-[0.2em] font-ringside-ssm-bold font-bold lg:text-[16px] lg:leading-[16px] text-[13px] leading-[13px] py-6 md:py-8">
            As Featured In
          </p>
          <div className="grid overflow-hidden grid-cols-2 grid-rows-2 sm:grid-cols-4 sm:grid-rows-1 gap-8 p-3 text-center justify-center align-middle content-center">
            {logos.map((logo, index) => (
              <div key={index}>
                <img className={logo.style} src={logo.logoMobile} alt="" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}
