import SingleReview from './SingleReview'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

export default function FAQ() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }

  const items = [
    {
      title: 'Best Meat Ever',
      content:
        'Super delicious, good quality meat! The best meat around, and even better that they are individually portioned and have no fat to trim off!! So thankful to have found this meat!',
      date: '10/24/22',
      author: 'Kathleen F.',
    },
    {
      title: 'You Won’t Regret it!!',
      content:
        "We have been subscribed for almost a year now and can't explain enough how wonderful this meat is. Every gathering and Family event people can't stop talking about how good and tender the meat tastes. Worth every dollar even in hard times.",
      date: '10/22/22',
      author: 'Greg H.',
    },
    {
      title: 'OUTSTANDING!',
      content:
        'BEST chicken I’ve ever bought! So different from what you get in the grocery store. Excellent quality - so soft and tender I can cut it with a fork! Never buying chicken anywhere else ❤️',
      date: '10/13/22',
      author: 'Luanne S.',
    },
    {
      title: 'Thank you!',
      content:
        "I love the product! It has come to the point that if I have no chicken from Good Ranchers I hesitate to get some from the store. I do get grossed out when I have to. My family can tell when I didn't use Good Ranchers chicken. I love the way it is packaged, how it tastes, I have peace of mind that it is better for my family and its from the USA.",
      date: '11/20/23',
      author: 'Marcela J.',
    },
    {
      title: 'Customer for Life',
      content:
        'This is the best product. I only get my meat from Good Ranchers. Chicken is the best ever. Steaks are wonderful.. I will be a customer for life.',
      date: '10/08/22',
      author: 'Cassie R.',
    },
    {
      title: 'Mean Lean Salmon!',
      content:
        "Meat eaters in our house have loved GR for every meal. Seafood isn't served often, but GR makes Surf & Turf possible with this box. The Shrimp are much more plump than what you get at the store and fully trimmed. The salmon is super lean and oh so tasty. We smoked the first batch and man I could eat that for the remainder of my years on this earth. The Cod and Halibut are so nice and tender as well. When Fried, they make awesome fish tacos. Well worth the subscription!",
      date: '10/30/23',
      author: 'Greg H.',
    },
    {
      title: 'Amazing Customer Service',
      content:
        "Anytime I've had a question, they have always been responsive. I've even received a few calls just to check in and make sure all is well and that I like everything I'm getting. At a time when it seems so many places have forgotten what customer service is about, Good Ranchers has not. ",
      date: '05/10/22',
      author: 'Kim M.',
    },
    {
      title: 'Best Decision Ever',
      content:
        'The packaging and portion size = perfect. Our family decided to stop buying meat from stores because we wanted something fresh, healthy, and Good Ranchers guarantees that. Good Ranchers also keeps us informed about what’s happening with meat products. We haven’t been disappointed. And the subscription plans offered make it super convenient to purchase. Thank you!',
      date: '10/31/23',
      author: 'Kerry P.',
    },
  ]

  return (
    <>
      <div className="flex justify-center p-4">
        <div className="flex flex-col items-center justify-center text-cream-400 lg:gap-[32px] gap-[16px] max-w-[100%] md:max-w-[1100px]">
          <div className="text-center max-w-[300px] lg:max-w-none flex justify-center m-auto">
            <div className="text-night-800 font-knockout-71 font-[400] leading-[30px] text-[40px] lg:text-[60px] mt-0 mb-4 md:mb-8 uppercase">
              WHAT PEOPLE ARE SAYING
            </div>
          </div>
          <div className="block w-[320px] lg:w-full">
            {/* @ts-ignore */}
            <Carousel
              renderButtonGroupOutside={true}
              responsive={responsive}
              infinite={true}
              autoPlay={true}
              ssr={true} // means to render carousel on server-side.
              draggable={false}
            >
              {items.map((item, index) => (
                <div className="flex justify-center">
                  {/* @ts-ignore */}
                  <SingleReview
                    title={item.title}
                    content={item.content}
                    date={item.date}
                    key={index}
                    author={item.author}
                  />
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </>
  )
}
