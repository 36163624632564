import Link from 'next/link'
import router, { useRouter } from 'next/router'

const ArticleCard = ({
  img,
  title,
  url,
  tags,
  date,
  bgColor,
}: {
  img: string
  title: string
  url: string
  tags: string[]
  date: string
  bgColor: string
}) => {
  const router = useRouter()

  const handleClick = () => {
    router.push(url)
  }

  return (
    <>
      <Link href={url} passHref>
        <a href={url} className="no-underline">
          <div
            onClick={handleClick}
            className={`article-card-container-snippet-tw ${bgColor} hover:cursor-pointer`}
          >
            <img className="article-card-img-tw" src={img} alt={title} />

            <div className="article-card-info-container-tw">
              <span className="article-card-info-title-snippet-tw no-underline visited:text-grayscale-coal-900 text-grayscale-coal-900 hover:text-grayscale-coal-900">
                {title}
              </span>
              <ArticleTags tags={tags} />
              <span className="article-card-info-date-tw">{date}</span>
            </div>
          </div>
        </a>
      </Link>
    </>
  )
}

const ArticleTags = (props: { tags: string[] }) => {
  const { tags } = props
  return (
    <>
      <div className="article-card-tags-container-tw">
        {tags.map((tag, index) => {
          return (
            <div key={index}>
              <span className="article-card-tags-tw">{tag}</span>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default ArticleCard
