import { UserContext } from 'contexts/user-context'
import { useContext } from 'react'
import StickerStar from '@components/icons/StickerStar'

const ProductSticker = ({
  stickerColor,
  stickerText,
  stickerTextColor,
  subscriberStickerText,
}: {
  stickerText: string
  stickerColor: string
  stickerTextColor: string
  subscriberStickerText?: string
}) => {
  const {
    state: { isSubscriber },
  } = useContext(UserContext)

  return (
    <div className="absolute z-10 ml-[-10px] mt-[-15px] md:ml-[-15px] md:mt-[-30px] lg:ml-[-25px] lg:mt-[-40px] flex items-center text-center justify-center">
      <StickerStar stickerColor={stickerColor} />

      <p
        className={` whitespace-pre-line absolute text-4xs md:text-xs lg:text-lg leading-none text-transform: uppercase -rotate-12 font-bold font-ringside-compressed-ssm-bold text-${stickerTextColor}`}
      >
        {isSubscriber && subscriberStickerText
          ? subscriberStickerText
          : stickerText}
      </p>
    </div>
  )
}

export default ProductSticker
