declare const window: any
const JoinWaitListButton = () => {
  const klaviyoClickListener = () => {
    window._klOnsite = window._klOnsite || []
    window?._klOnsite.push(['openForm', 'Vjn2hQ'])
  }

  return (
    <>
      <div
        onClick={() => klaviyoClickListener()}
        className="btn-secondary-tw bg-yolk-500 hover:cursor-pointer flex flex-col items-center content-center justify-center text-center h-full w-full klaviyo_form_trigger"
      >
        <span className="font-ringside-compressed-ssm-medium font-[900] lg:text-[20px] text-[14px] lg:leading-5 sm:leading-4 leading-4 uppercase sm:before:content-['Join_the_waitlist'] before:content-['Join_Waitlist'] text-grayscale-coal-900"></span>
      </div>
    </>
  )
}

export default JoinWaitListButton
