const FeatureCard = ({
  icon,
  altText,
  text,
}: {
  icon: string
  altText: string
  text: string
}) => {
  return (
    <div
      className="flex  flex-col items-center sm:pt-6 sm:px-6  sm:pb-8 md:p-2 lg:pt-[20px] lg:px-2 lg:pb-7 pt-4 px-3 pb-[20px] 2xs:w-[150px] sm:h-[px] sm:w-[170px] lg:h-[215px] lg:w-[235px] w-[164px] h-[152px] xs:h-[162px] 2xs:h-[162px] sm:rounded-[12px] rounded-[8px] bg-cream-300 max-w-[90%] 2xs:max-w-full" /* eslint-disabl */
    >
      <img
        className="sm:w-[48px] sm:h-[48px] lg:w-[80px] lg:h-[80px]  w-[48px] h-[48px] sm:mb-0 flex-none order-[0] flex-grow-0"
        alt={altText}
        src={icon}
      />
      <span style={{ whiteSpace: 'pre-line' }}>
        <p className="font-knockout-49 sm:text-[24px] lg:text-[24px] text-[24px] not-italic sm:leading-6 leading-[18px] font-[360] items-end text-center uppercase flex-none order-1 sm:w-[208px] sm:h-[45px] sm:mt-1 w-[140px] h-fit text-night-800 mt-[8px] px-2 md:mt-4 md:mb-4">
          {' '}
          {text}
        </p>
      </span>
    </div>
  )
}

export default FeatureCard
