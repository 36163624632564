const StickerStar = ({ stickerColor }) => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="w-[64px] md:w-[96px] lg:w-[128px] h-[64px] md:h-[96px] lg:h-[128px]"
    >
      <path
        d="M32 0L38.6258 7.2723L48 4.28719L50.1019 13.8981L59.7128 16L56.7277 25.3742L64 32L56.7277 38.6258L59.7128 48L50.1019 50.1019L48 59.7128L38.6258 56.7277L32 64L25.3742 56.7277L16 59.7128L13.8981 50.1019L4.28719 48L7.2723 38.6258L0 32L7.2723 25.3742L4.28719 16L13.8981 13.8981L16 4.28719L25.3742 7.2723L32 0Z"
        fill={stickerColor}
      />
    </svg>
  )
}

export default StickerStar
