import { useWindowSize } from 'hooks'
import { Product } from 'types/products'
import ImageGallery from 'react-image-gallery'
import * as _ from 'lodash'
import { slugifyName, getThumbnailImage, convertImages } from '@utils/products'
import { useRouter } from 'next/router'

// TODO: Update with Tailwind
const leftNavBtn = (onClick) => {
  return (
    <div
      className="image-gallery-icon"
      style={{
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        padding: '0 20px',
      }}
      onClick={onClick}
    >
      <div
        className="left-nav-icon"
        style={{
          position: 'absolute',
          left: 5,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '60px',
          width: '40px',
          backgroundColor: 'var(--light)',
          borderRadius: '50%',
          transform: 'translateX(-40px)',
          transition: 'transform 0.1s linear',
        }}
      >
        <i className="fa fa-chevron-left" />
      </div>
    </div>
  )
}

// TODO: Update with Tailwind
const rightNavBtn = (onClick) => {
  return (
    <div
      className="image-gallery-icon"
      style={{
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        right: 5,
        height: '100%',
        padding: '0 20px',
      }}
      onClick={onClick}
    >
      <div
        className="right-nav-icon"
        style={{
          position: 'absolute',
          right: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '60px',
          width: '40px',
          backgroundColor: 'var(--light)',
          borderRadius: '50%',
          transform: 'translateX(40px)',
          transition: 'transform 0.1s linear',
        }}
      >
        <i className="fa fa-chevron-right" />
      </div>
    </div>
  )
}

// TODO: Fix Image Stretching on Ipad
const ProductCardCarousel = ({
  product,
  deal,
}: {
  product: Product
  deal?: boolean
}) => {
  const router = useRouter()

  const handleClick = () => {
    router.push(`/product/${slugifyName(product.name)}`)
  }

  const windowSize = useWindowSize()
  const pageWidth = windowSize?.width || 0
  const productImages = convertImages(product?.images)
  const thumbnail = getThumbnailImage(product?.images)
  const images = [thumbnail, ...productImages]
  return productImages ? (
    <ImageGallery
      items={images}
      showIndex={false}
      showFullscreenButton={false}
      showPlayButton={false}
      showNav={pageWidth > 640 ? true : false}
      disableKeyDown={true}
      renderItem={(item) => {
        return (
          <img
            onClick={handleClick}
            draggable={false}
            className={` object-cover ${
              deal
                ? 'lg:rounded-tl-xl lg:rounded-tr-none lg:h-[320px] sm:h-[250px] h-[240px]'
                : 'product-card-image-tw'
            } rounded-t-lg hover:cursor-pointer`}
            alt={product.name}
            src={item.original}
          />
        )
      }}
      renderThumbInner={(item) => {
        return (
          <img
            className={` object-cover ${
              deal
                ? 'lg:rounded-tl-xl lg:rounded-tr-none lg:h-[320px] sm:h-[250px] h-[240px]'
                : 'product-card-image-tw'
            } rounded-t-lg hover:cursor-pointer`}
            alt={product.name}
            src={item.thumbnail}
          />
        )
      }}
      renderLeftNav={leftNavBtn}
      renderRightNav={rightNavBtn}
    />
  ) : null
}

export default ProductCardCarousel
