
import * as React from 'react'

const SingleReview = ({
  title,
  content,
  date,
  author,
}: {
  title: string
  content: string
  date?: string
  author?: string
}) => {
  {
    return (
      <div className="bg-white p-4 md:p-6 rounded-md  max-w-[250px] sm:max-w-[312px] lg:max-w-[250px] xl:max-w-[312px]">
        <div>
          <div className="text-night-800 font-[600] font-ringside-ssm-medium text-sm sm:text-lg mb-0 mt-0">
            {title}
          </div>
          <div className="flex">
            <div className="flex justify-center align-middle items-baseline">
              <img
                className="card-reviews-stars-tw"
                src="/img/reviews/grade.svg"
                alt=""
              />
              <img
                className="card-reviews-stars-tw"
                src="/img/reviews/grade.svg"
                alt=""
              />
              <img
                className="card-reviews-stars-tw"
                src="/img/reviews/grade.svg"
                alt=""
              />
              <img
                className="card-reviews-stars-tw"
                src="/img/reviews/grade.svg"
                alt=""
              />
              <img
                className="card-reviews-stars-tw"
                src="/img/reviews/grade.svg"
                alt=""
              />
            </div>
          </div>
        </div>
        <div>
          <p className="text-grayscale-coal-900 text-4xs font-ringside-ssm-lightitalic sm:text-2xs mt-2 md:mt-3 text-start">
            {content}
          </p>
        </div>
        <div className="flex">
          <div className="flex items-center">
            <img
              src="/img/reviews/verified.svg"
              alt=""
              className="h-[18px] w-[18px]"
            />
            <p className="text-sage-700 text-5xs md:text-4xs m-0 ml-[3px]">
              Verified Buyer
            </p>
          </div>
          <div className="flex-grow"></div>
          <div>
            <p className="text-black font-bold font-ringside-ssm-bold text-2xs md:text-sm m-0">
              -{author}
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default SingleReview
