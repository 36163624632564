import Link from 'next/link'

const HowItWorks = ({
  icon,
  title,
  body,
}: {
  icon: string
  title: string
  body: string | JSX.Element
}) => {
  return (
    /* eslint-disable */
    <div className="max-w-full w-[384px] flex flex-col items-center p-0 lg:gap-8 gap-4 flex-none flex-grow-0">
      <img
        className="lg:w-80 w-48 lg:h-20 h-12 flex-none flex-grow-0"
        alt="how it works"
        src={icon}
      />
      <div className="max-w-full flex flex-col items-center p-0 mx-5 lg:mx-0 gap-2 flex-none self-stretch flex-grow-0">
        <span className="font-knockout-49 font-[360] lg:text-[40px] text-xl lg:leading-[30px] leading-[18px] flex items-center text-center uppercase text-night-800 flex-grow-0">
          {title}
        </span>
        <span className="font-ringside-ssm-light font-semibold lg:text-lg lg:leading-[30px] text-sm leading-6 text-center flex items-end text-night-800">
          {body}
        </span>
      </div>
    </div>
  )
}

export default function Features() {
  const features = [
    {
      icon: '/img/rebrand/Understand-The-Problem.svg',
      title: 'Understand The Problem',

      body: (
        <span>
          {''}You can’t trust the meat at the grocery store. Uncover the 3
          biggest mysteries here - {''}
          <a
            href="https://www.goodranchers.com/blog/how-us-beef-imports-are-hurting-americans"
            className="text-beef-500"
          >
            Learn More
          </a>
        </span>
      ),
    },
    {
      icon: '/img/rebrand/box.svg',
      title: 'Pick your Box',
      body:
        'Find the perfect box for you in our selection of 100% American meat & wild-caught seafood',
    },
    {
      icon: '/img/rebrand/Savor-Every-Bite.svg',
      title: 'Savor Every Bite',
      body:
        'Enjoy easy, delicious, and trustworthy meals delivered to your door as you forget about the meat aisle for good.',
    },
  ]

  return (
    <div className="bg-white mt-[70px]">
      <div className="max-w-full flex justify-center">
        <div className="text-center">
          <h4 className="text-beef-500 font-knockout-71 text-[40px] sm:text-6xl mt-0 md:mt-[10px] leading-[30px] mb-8 md:mb-12 lg:mb-16 font-[415]">
            HOW IT WORKS
          </h4>
          <div className="grid overflow-hidden grid-cols-1 lg:grid-cols-3  gap-8 text-center align-middle content-center">
            {features.map((feature) => (
              <div className="flex justify-center" key={feature.title}>
                <HowItWorks
                  icon={feature.icon}
                  title={feature.title}
                  body={feature.body}
                />
              </div>
            ))}
          </div>
          <div className="w-full m-auto lg:mt-12 md:pt-6 xs:pt-6 2xs:pt-6 ">
            <div className="text-center">
              <Link href="/shop">
                <button className="font-ringside-compressed-ssm-black text-sm lg:text-lg text-darkBlue bg-yolk-500 uppercase lg:py-3 xs:py-2 2xs:py-2 px-4 border-3 border-yolk-500  border-solid	hover:bg-yolk-300 hover:border-yolk-300 hover:cursor-pointer active:border-yolk-300 active:bg-yolk-300 no-underline">
                  Get Started
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
