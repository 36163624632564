import FeatureCard from '@components/Card/FeatureCard/FeatureCard'

export default function Features() {
  const features = [
    {
      icon: '/img/rebrand/americanFlag.svg',
      text: 'Only 100%\n American Farm-\nRaised Meat',
      altText: 'American Flag',
    },
    {
      icon: '/img/rebrand/medal.svg',
      text: 'USDA Prime\n & Upper Choice Quality',
      altText: 'Best',
    },
    {
      icon: '/img/rebrand/syringe.png',
      text: 'No mRNA\nVaccines or\nAntibiotics',
      altText: 'Skull and Crossbones',
    },
    {
      icon: '/img/rebrand/cowboyEmoji.svg',
      text: 'Sourced\nSustainably and Locally',
      altText: 'Cowboy',
    },
  ]

  return (
    <>
      <div className="max-w-[1120px] flex justify-center m-auto">
        <div className="grid overflow-hidden grid-cols-2 grid-rows-2 z-[1] md:grid-cols-4 sm:grid-rows-1 gap-8 bg-transparent p-3 text-center justify-center align-middle content-center mt-[-80px] lg:mt-[-100px]">
          {features.map((feature, index) => (
            <div className="flex justify-center" key={index}>
              <FeatureCard
                icon={feature.icon}
                altText={feature.altText}
                text={feature.text}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
