import { useEffect, useState } from 'react'
import { Product, ProductVariantOptions } from 'types/products'
import ProteinIndicator from './ProteinIndicator'
import ProductInfo from './ProductInfo'
import ProductCardCarousel from './ProductCardCarousel'
import { contents } from '../../../utils/ProductData'
import ProductSticker from '../ProductCard/ProductSticker'
import { getVariants } from 'utils/products'

const ProductCard = ({ product }: { product: Product }) => {
  const [variants, setVariants] = useState<ProductVariantOptions[] | null>(null)
  const [
    selectedVariant,
    setSelectedVariant,
  ] = useState<ProductVariantOptions | null>(null)

  useEffect(() => {
    if (product?.variants?.length > 1) {
      const variants = getVariants(product)
      setVariants(variants)
      setSelectedVariant(variants[0])
    }
  }, [product])
  return (
    <>
      <div className="lg:h-fit sm:h-fit h-fit lg:w-[310px] xl:w-[384px] sm:w-[280px] w-[164px] flex flex-col items-start sm:p-0 isolate flex-none  bg-grayscale-coal_white lg:rounded-[12px_12px_12px_12px] sm:rounded-[8px_8px_8px_8px] rounded-[8px_8px_8px_8px]">
        {contents[product.id]?.sticker ? (
          <ProductSticker
            stickerColor={contents[product.id]?.stickerColor}
            stickerText={contents[product.id]?.stickerTitle}
            stickerTextColor={contents[product.id]?.stickerTextColor}
          />
        ) : null}
        <ProductCardCarousel product={product} />
        <ProteinIndicator product={product} />
        <ProductInfo product={product} selectedVariant={selectedVariant} />
      </div>
    </>
  )
}

export default ProductCard
